@media only screen and (max-width: 767px) {
    .slide{
        background-position: 10% 0;
        &#first-slide{
            background-size: cover;
            background-position: 30% 0;
            & .slide__left{
                height: 100%;
            }
            & .slide__inner{
                padding: 0 2%;
            }
        }
        &#second-slide{
            background-image: url(../img/fond_msf-mob.jpg);
            background-size: cover;
            background-position: 30% 0;
        }

        &--second{
            & .slide__left{
                padding: 0 0 0 0 ;
            }
        }
        &__inner{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0% 5% 20%;
            &--down{
                justify-content: flex-start;
                height:200vh;
                padding: 0;

                & .slide__left{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 80px 5% 0;
                    height: 100vh;
                    box-sizing: border-box;
                }
            }
        }
   
        &__left{
            width: 100%;
            height: auto;
            padding:20% 0 0 0 ;
        }
        &__right{
            width: 100%;
            height: auto;
            padding:5% 0 5% 0 ;
        }
    
        &__title{
            font-size: 30px;
            margin-top: 10px;
            max-width: 100%;
            &#last-title{
                font-size: 25px;
                max-width: 90%;
                margin-bottom: 10px;
            }
        } 
        &__paragraph{
            font-size: 12px;
            margin-bottom: 12px;
            width: 100%;
            &.closed{
                display: block; /* Fallback for non-webkit */
                display: -webkit-box;
                max-width: 100%;
                height: 12px*1.1*4; /* Fallback for non-webkit */
                margin: 0 auto 12px auto;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &#slide-donation{
            height: 300vh;
            display: flex;
            flex-direction: column;
        }   
    }
    
    .skip{
        width: 90%;
        left: 5%;
        right: initial;
        bottom: 80px;
        justify-content: space-between;
    }
    
    .progression{
        flex-direction: column;
        align-items: flex-start;
        bottom: 60px;
        &__content{
            margin-bottom: 20px;
        }
        &__bar{
            max-width: 300px;
        }
    }

    #main_cta{
        bottom: 0px;
        right: 0%;
        width: 100%;
    }

    br{
        display: none;
    }

    .pepaid{
        width: 120px;
        margin-bottom: 10px;
    }

    .donation{
        width: 100%;
        height: 30vh;
        margin: 20px 0 0;
        justify-content: center;
        align-items: center;
        &__container{
            flex-direction: column;
        }
    }

    .logo{
        width: auto;
        height: 32px;
        &__link{
            width: auto;
            height: 32px;
        }
    }

    .footer{
        height: 100vh;
        padding: 60px 0 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        &__container{
            flex-direction: column;
            justify-content: space-around;
            height: 90%;
        }
        &__left, &__middle, &__right{
            width: 100%;
            justify-content: center;
            align-items: center;
        }
        &__info,&__legal{
            text-align: center;
        }

    }
    #languageSwitch{
        font-size: 15px;
    }
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
//   and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slide{
        background-position: 10% 0;
        &#first-slide{
            background-size: cover;
            background-position: 30% 0;
            & .slide__left{
                height: 100%;
            }
            & .slide__inner{
                padding: 0 2%;
            }
        }
        &#second-slide{
            background-image: url(../img/fond_msf-mob.jpg);
            background-size: cover;
            background-position: 30% 0;
        }

        &--second{
            & .slide__left{
                padding: 0 0 0 0 ;
            }
        }
        &__inner{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 100px 5% 20%;
            &--down{
                justify-content: flex-start;
                padding: 0;
                & .slide__left{
                    justify-content: flex-start;
                    padding: 100px 5% 0;
                }
            }
        }
   
        &__left{
            width: 100%;
            height: auto;
            padding:20% 0 0 0 ;
        }
        &__right{
            width: 100%;
            height: auto;
            padding:5% 0 5% 0 ;
        }
    
        &__title{
            font-size: 40px;
            margin-top: 10px;
            max-width: 100%;
            &#last-title{
                font-size: 25px;
                max-width: 90%;
                margin-bottom: 10px;
            }
        } 
        &__paragraph{
            font-size: 12px;
            margin-bottom: 12px;
            width: 100%;
        } 
    }
    
    .skip{
        bottom: 120px;
        justify-content: space-between;
    }
    
    .progression{
        bottom: 120px;
        &__bar{
            max-width: 300px;
        }
    }

}