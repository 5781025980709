
.cta{
    font-size: 14px;
    background-color: #EE0000;
    color: white;
    font-family: "Avenir-Medium",Arial, Helvetica, sans-serif;
    padding: 15px 20px;
    box-sizing: border-box;
    display: block;
    width: 250px;
    text-decoration: none;
    display: flex;
    justify-content: space-between; 
}

#main_cta{
    bottom: 45px;
    left: initial;
    right: 5%;
    position: fixed;
    z-index: 500; 
}

.donation{
    height: 40vh;
    width: 40vh;
    margin: 20px 20px 0 0;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &__container{
        display: flex;
        flex-direction: row;
    }
    &#classic-donation{
        background-image: url(../img/don1.jpg);
    }
    &#prepaid-donation{
        background-image: url(../img/don2-fr.gif);
    }
}

.prepaid{
    width: 180px;
    margin-bottom: 20px;
    &.hide{
        display: none;;
    }
}