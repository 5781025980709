.main{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #202020;
    &__inner{
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100vw;
        position: relative;
    }
    #first-slider{
        position: relative;
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 200vw;
    }
    #second-slider{
        height: 100%;
        width: 400vw;
        position: absolute;
        left: 100vw;
        display: flex;
        flex-direction: row;
        transform: scale(.7);
        transform-origin: 0% 50%;
        -moz-transform-origin: 0% 50%;
        -webkit-transform-origin: 0% 50%;
    }
}

.skip{
    display: flex;
    width: 250px;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: "Avenir-Medium",Arial, Helvetica, sans-serif;
    align-content: center;
    position: absolute;
    bottom: 20%;
    right: 5%;
    &__link{
        color: white;
        text-decoration: none;
    }

}
.logo{
    width: auto;
    height: 46px;
    &__link{
        position: fixed;
        top: 5%;
        left: 5%;
        z-index: 600;
        width: auto;
        height: 46px;
        &--footer{
            position: static;
        }
    }
}

.bullet{
    margin-right: 25px;
}

#languageSwitch{
    position: fixed;
    top: 5%;
    right: 5%;
    z-index: 600;
    color: white;
    text-decoration: none;
    font-size: 25px;
    font-family: "Avenir-Black";
}
