.progression{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    color: white;
    // font-family: Arial, Helvetica, sans-serif;
    font-family: "Avenir-Medium",Arial, Helvetica, sans-serif;

    position: fixed;
    bottom: 30%;
    left: 5%;
    z-index: 500;
    &__content{
        display: flex;
        justify-content: flex-start;
        align-items: center;        
        white-space: nowrap;
    }
    &__arrow{
        margin: 0 20px;
    }
    &__text{
        margin: 0 0px;
        cursor: pointer;

        &:hover {
            color: #e00
        }
    }
    &__bar{
        width: 100%;
        max-width: 450px;
        height: 2px;
        background-color: white;
        transform-origin: 0 50%;
    }
    .bullet{
        margin-right: 20px;
    }

    @media only screen and (min-width: 768px) {
        width: 65%;
    }
}