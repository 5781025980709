.footer{
    width: 100%;
    height: 25%;
    background: #111111;
    padding: 20px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: "Avenir-Book",Arial, Helvetica, sans-serif;
    font-size: 14px;

    &__container{
        width: 90%;
        display: flex;
        max-width: 1400px;
        flex-direction: row;
        justify-content: center;
    }
    &__left{
        width: 30%;
        display: flex;
        flex-direction: column;
    }
    &__middle{
        width: 30%;
        display: flex;
        flex-direction: column;
    }
    &__right{
        width: 30%;
        display: flex;
        flex-direction: column;
    }
    &__info{
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        &-item{
            margin-bottom: 5px;
        }
    }
    &__legal{
        list-style: none;
        margin: 0;
        padding: 0;
        &-item{
            margin-bottom: 5px;
        }
    }
    &__social{
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0;
        padding: 0;
        &-item{
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        img{
            width: 30px;
            height: 30px;
        }
    }
    &__link{
        color: white;
        margin-bottom: 10px;
    }

    &__title{
        margin: 0;
        font-size: 15px;
        font-family: "Avenir-Black", "Avenir-Medium",Arial, Helvetica, sans-serif;
        font-weight: normal;
        margin-bottom: 20px;
    }
    .logo__link--footer{
        position: static;
        margin-bottom: 20px;
        height: 60px;
        width: auto;
        img {
            height: 60px;
            width: auto;
        }
    }
}