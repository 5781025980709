.slide{
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    &#first-slide{
        width: 200vw;
        background-image: url(../img/fond_intro2.jpg);
        background-size: initial;
        background-color: #202020;
        display: flex;
        flex-direction: row;
        position: relative;
        top: 0;
        left: 0;
        z-index: 100;
        & .slide__left{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;

        }
    }
    &#second-slide{
        background-image: url(../img/fond_msf.jpg);
        background-size: initial;
        background-position-x: 0%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &#slide-donation{
        height: 200vh;
        background-image: url(../img/donation.jpg);
        display: flex;
        flex-direction: column;
    }
 
    &#case-0{
    }
 
    &#case-1{
        background-image: url(../img/fond_case-1.jpg);
    }
    &#case-2{
        background-image: url(../img/fond_case-2.jpg);
    }
    &#case-3{
        background-image: url(../img/fond_case-3.jpg);
    }
    &#last-case{
        background-image: url(../img/fond_case-4.jpg);
        &.is-active{
            overflow: visible;
        }
    }

    &--second{
        & .slide__left{
            padding:40px 0 0 0 ;
        }
    }

    &__inner{
        padding: 5vh 5%;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        color: white;
        font-family: "Avenir-Book",Arial, Helvetica, sans-serif;
        background-color: rgba(32,32,32,.6);
        &--left{
            position: relative;
        }
        &--down{
            position: relative;
            height: 100vh;
            padding: 0;
            background-color: initial;
            display: flex;
            flex-direction: column;
            justify-content: center;

            & .slide__left{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 5% 10% 40px;
                height: 75%;
                box-sizing: border-box;
            }
        }
        &--up{
            height: 100vh;
            background-color: initial;
        }
    }

    &__left{
        width: 70%;
        height: 100%;
        box-sizing: border-box;
        padding:15% 0 0 0 ;
    }
    &__right{
        width: 30%;
        height: 100%;
        padding: 0 0 25vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
 
    &__title{
        font-size: 60px;
        margin:0;
        font-weight: normal;
        margin-top: 20px;
        *{
            display: inline !important;
        }
        &#last-title{
            font-size: 35px;
            font-family: "Avenir-Black",Arial, Helvetica, sans-serif;

        }
    }
    &__subtitle{
        margin:0;
        font-weight: normal;
        font-size: 14px;
        font-family: "Avenir-Medium",Arial, Helvetica, sans-serif;

    }
    &__paragraph{
        font-size: 14px;
        line-height: 1.1;
        margin:0;
        margin-bottom: 14px;
        width: 100%;
        transition: all 0.5s ease-out;
        &.closed{
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            max-width: 100%;
            height: 14px*1.1*4; /* Fallback for non-webkit */
            margin: 0 auto 14px auto;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__link{
        font-size: 14px;
        color: white;
        // margin-top: auto;
        display: block;
        width: 100px;
        cursor: pointer;
        &.hide{
            display: none;
            visibility: hide;
            opacity: 0;
        }
    }

}



