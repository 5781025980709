@font-face {
    font-family: "Avenir-Book";
    src: url("../fonts/Avenir-Book.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Avenir-Medium";
    src: url("../fonts/Avenir-Medium.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Avenir-Black";
    src: url("../fonts/Avenir-Black.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "AvenirNext";
    src: url("../fonts/AvenirNext-Medium.ttf") format("truetype");
    font-weight: normal;
}